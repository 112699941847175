import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function Footer() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  function Contents() {
    return (
      <>
        <div className='grid'>
          <div className='s6 m4'>
            <h6>{t('footer_company')}</h6>
            <p>
              <a
                className='link'
                onClick={e => {
                  e.preventDefault()
                  navigate('/about')
                  return false
                }}
                href='/about'>
                {t('footer_about')}
              </a>
            </p>
            <p>
              <a
                className='link'
                onClick={e => {
                  e.preventDefault()
                  navigate('/contact')
                  return false
                }}
                href='/contact'>
                {t('footer_contact_link')}
              </a>
            </p>
          </div>

          <div className='s6 m4'>
            <h6>{t('footer_legal')}</h6>
            <p>
              <a className='link' target='_blank' rel='noopener noreferrer' href={t('link_privacy')}>
                {t('footer_privacy')}
              </a>
            </p>
            <p>
              <a className='link' target='_blank' rel='noopener noreferrer' href={t('link_terms')}>
                {t('footer_terms')}
              </a>
            </p>
          </div>

          <div className='s6 m4'>
            <h6>{t('footer_contact')}</h6>
            <a className='link' href='mailto: contact@iqataxtools.com'>
              <i>mail</i> contact@iqataxtools.com
            </a>
          </div>
        </div>
        <div className='small-divider' />
        <nav>
          <span className='max'>{t('footer_copyright')}</span><span className='m l'>v{process.env.APP_VERSION}</span>
        </nav>
      </>
    )
  }

  return (
    <>
      <footer className='large-padding bottom'>{Contents()}</footer>
    </>
  )
}
